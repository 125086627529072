import React from 'react'
import { Button, Section } from 'src/components/shared'
import styles from './UnsubscribeOptions.module.scss'

const UnsubscribeOptions = props => {
  return (
    <Section size="lg" className={styles.container}>
      <h1 className={styles.title}>How many meerkats do you want in your life?</h1>
      <h2 className={styles.subtitle}>The meerkats will miss you</h2>
      <div className={styles.optionsContainer}>
        <div className={styles.option}>
          <h3 className={styles.optionTitle}>Unsubscribe from {props.data.name} ONLY</h3>
          <span className={styles.text}>
            {props.data.address}
          </span>
          <Button
            className={styles.button}
            onClick={() => props.open(props.data.address)}
            text="Unsubscribe"
          />
          <span className={styles.text}>
            You will stop receiving alerts for inbound and outbound transactions
            from {props.data.name}
          </span>
        </div>
        <div className={styles.option}>
          <h3 className={styles.optionTitle}>Unsubscribe from ALL addresses</h3>
          <span className={styles.text}>
            {props.data.subscriptionCount} addresses currently being watched
          </span>
          <Button
            className={styles.button}
            onClick={() => props.open('ALL')}
            text="Unsubscribe from All"
          />
          <span className={styles.text}>
            You will stop receiving alerts for inbound and outbound transactions
            from all your addresses
          </span>
        </div>
      </div>
    </Section>
  )
}

export default UnsubscribeOptions
