import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Navbar from '../components/shared/Navbar'
import Unsubscribe from 'src/components/pages/Unsubscribe/Unsubscribe'

const UnsubscribePage = (props) => (
  <Layout>
    <Helmet title="Meerkat - Unsubscribe" />
    <Navbar />
    <Unsubscribe location={props.location} />
  </Layout>
)

export default UnsubscribePage
