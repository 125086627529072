import React from 'react'
import axios from 'axios'
import { parse } from 'qs'
import classNames from 'classnames/bind'
import { Button, Modal } from 'src/components/shared'
import Landscape from 'src/assets/images/landscape-unsubscribe.svg'
import UnsubscribeOptions from '../UnsubscribeOptions'
import UnsubscribeConfirmation from '../UnsubscribeConfirmation'
import styles from './Unsubscribe.module.scss'

const cx = classNames.bind(styles)

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props)

    const { id } = parse(props.location.search, {
      ignoreQueryPrefix: true,
    })

    this.state = {
      id,
      isUnsubscribed: false,
      isOpen: false,
      data: {},
    }
  }

  componentDidMount() {
    axios
      .get(`/api/v0/subscriptions/${this.state.id}`)
      .then(({ data }) => {
        this.setState({ isSuccessful: true, data })
      })
      .catch(() => this.setState({ isSuccessful: false }))
  }

  unsubscribe = () => {
    axios
      .post(`/api/v0/subscriptions/${this.state.id}/unsubscribe`)
      .then(() => {
        this.setState({ isConfirmed: true, isOpen: false, error: false })
      })
      .catch(() => {
        this.setState({ error: true })
      })
  }

  open = type => {
    this.setState({ isOpen: true, type })
  }

  render() {
    const { isConfirmed, isOpen, error } = this.state

    return (
      <React.Fragment>
        {!isConfirmed && (
          <UnsubscribeOptions data={this.state.data} open={this.open} />
        )}
        {isConfirmed && <UnsubscribeConfirmation />}
        <img
          alt="meerkats landscape"
          className={styles.image}
          src={Landscape}
        />

        <Modal
          closeModal={() => this.setState({ isOpen: false, error: false })}
          isOpen={isOpen}
          size="medium"
        >
          <div className={styles.modalContent}>
            <h2 className={styles.modalTitle}>
              Confirm you want to unsubscribe from {this.state.type}
              {this.state.type === 'ALL' && 'addresses'}
            </h2>
            <span className={styles.text}>You will stop receiving:</span>
            <span className={styles.text}>
              Alerts for inbound and outbound transactions
            </span>
            {error && (
              <span className={cx('text', 'error')}>
                There was an error unsubscribing. Please try again.
              </span>
            )}
            <div className={styles.buttonContainer}>
              <Button
                className={cx('button', 'buttonUnsubscribe')}
                onClick={this.unsubscribe}
                text="Unsubscribe"
              />
              <Button
                className={styles.button}
                onClick={() => this.setState({ isOpen: false })}
                text="Cancel"
              />
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Unsubscribe
