import React from 'react'
import { LinkButton, Section } from 'src/components/shared'
import styles from './UnsubscribeConfirmation.module.scss'

const index = () => {
  return (
    <Section className={styles.container}>
      <h1>You have successfully unsubscribed</h1>
      <LinkButton to="/" className={styles.button} >
        Watch a new address
      </LinkButton>
    </Section>
  )
}

export default index
